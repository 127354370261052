import * as React from "react";
import {
  Wrapper,
  ListWrapper,
  LinkWrapper,
  ButtonWrapper,
  FalseLinkWrapper,
  Divider,
  SubListWrapper,
  ListHeader,
  ColumnWrapper,
  NavButton,
  MenuButton
} from "./Artistes.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";
import Button from "../../../components/Button";
import CoolTabs from 'react-cool-tabs';


const artists = [
  // {
  //   path: "delta-2",
  //   name: "DELTA 2",
  //   description: "Description",
  //   photos: ["path", "path", "path"],
  //   works: ["Works", "Works", "Works"],
  //   videos: ["Videos", "Videos", "Videos"],
  //   order: 0,
  // },

  {
    path: "dondi-white",
    name: "DONDI WHITE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },

  {
    path: "rammellzee",
    name: "RAMMELLZEE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 1,
  },
  {
    path: "futura-2000",
    name: "FUTURA (2000)",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 2,
  },
  {
    path: "lady-pink",
    name: "LADY PINK",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 3,
  },
  {
    path: "aone",
    name: "AONE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 4,
  },
  {
    path: "fab-5",
    name: "FAB 5",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 5,
  },

  {
    path: "lee-quinones",
    name: "LEE QUINONES",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 6,
  },
  {
    path: "crash",
    name: "CRASH",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 7,
  },
  {
    path: "quik",
    name: "QUIK",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 8,
  },

  {
    path: "daze",
    name: "DAZE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 9,
  },
  {
    path: "seen",
    name: "SEEN",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 10,
  },
 
  {
    path: "zephyr",
    name: "ZEPHYR",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 11,
  },  
 
  {
    path: "blade",
    name: "BLADE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 12,
  },
  {
    path: "bill-blast",
    name: "BILL BLAST",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 13,
  },
  {
    path: "kool-koor",
    name: "KOOL KOOR",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 14,
  },
  // {
  //   path: "iz-the-wiz",
  //   name: "IZ THE WIZ",
  //   description: "Description",
  //   photos: ["path", "path", "path"],
  //   works: ["Works", "Works", "Works"],
  //   videos: ["Videos", "Videos", "Videos"],
  //   order: 15,
  // },
  {
    path: "ERO",
    name: "ERO",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 16,
  },

  {
    path: "LAII",
    name: "LA II",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 17,
  },
  {
    path: "keith-haring",
    name: "KEITH HARING",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 18,
  },
  {
    path: "",
    name: "JEAN-MICHEL BASQUIAT",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 19,
  },
  {
    path: "kenny-scharf",
    name: "KENNY SCHARF",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 19,
  },
  {
    path: "richard-hambleton",
    name: "RICHARD HAMBLETON",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 20,
  },
  {
    path: "",
    name: "MARTIN WONG",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 21,
  },
  {
    path: "",
    name: "DON LEICHT",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 22,
  },
  {
    path: "",
    name: "JOHN FEKNER",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 23,
  },
  {
    path: "",
    name: "RICK PROL",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 24,
  },

  {
    path: "chaz-bojorquez",
    name: "CHAZ BOJORQUEZ",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 25,
  },
    {
    path: "noc",
    name: "NOC",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 26,
  },
  {
    path: "invader",
    name: "INVADER",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 27,
  },
  {
    path: "kaws",
    name: "KAWS",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 28,
  },
  
  {
    path: "shepard-fairey",
    name: "SHEPARD FAIREY",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 29,
  },
  {
    path: "",
    name: "DANIEL ARSHAM",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 30,
  },
 


  {
    path: "eddie-martinez",
    name: "EDDIE MARTINEZ",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 32,
  },
  {
    path: "",
    name: "JOSH SPERLING",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 33,
  },
  {
    path: "jammie-holmes",
    name: "JAMMIE HOLMES",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 34,
  },


  {
    path: "",
    name: "JORDY KERWICK",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 35,
  },

  {
    path: "todd-james",
    name: "TODD JAMES",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 36,
  },
  {
    path: "",
    name: "CHRIS JOHANSSON",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 36,
  },
  {
    path: "niels-shoe-meulman",
    name: "NIELS SHOE MEULMAN",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 39,
  },
  {
    path: "toxic",
    name: "TOXIC",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 40,
  },
  {
    path: "sharp",
    name: "SHARP",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 41,
  },
  {
    path: "",
    name: "JONONE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 41,
  },

  {
    path: "bando",
    name: "BANDO",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
  order: 42,
  },
  {
    path: "",
    name: "JAY ONE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
  order: 42,
  },
  {
    path: "",
    name: "GÉRARD ZLOTYKAMIEN",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 44,
  },
  ,
  {
    path: "",
    name: "SAM FRANCIS",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 45,
  },
  {
    path: "",
    name: "PETER SAUL",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 46,
  },
  {
    path: "herve-di-rosa",
    name: "HERVÉ DI ROSA",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 47,
  },
  {
    path: "ronnie-cutrone",
    name: "RONNIE CUTRONE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 48,
  },
  {
    path: "henry-chalfant",
    name: "HENRY CHALFANT",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 49,
  },
  {
    path: "ana-monso",
    name: "ANA MONSÓ",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 51,
  },
  {
    path: "mathias-bensimon",
    name: "MATHIAS BENSIMON",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 52,
  },
  {
    path:"",
    name: "CLÉMENCE APPIE GBONON",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },
  {
    path:"",
    name: "HAKIM SAHIRI",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },
  {
    path:"",
    name: "FÉLIX TABURET & HANNAH BECQUANTE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },
  {
    path:"",
    name: "DJABRIL BOUKHENAÏSSI",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },

  // {
  //   path: "jose-parla",
  //   name: "JOSÉ PARLA",
  //   description: "Description",
  //   photos: ["path", "path", "path"],
  //   works: ["Works", "Works", "Works"],
  //   videos: ["Videos", "Videos", "Videos"],
  // order: 31,
  // },
  // {
  //   path: "kase2",
  //   name: "KASE 2",
  //   description: "Description",
  //   photos: ["path", "path", "path"],
  //   works: ["Works", "Works", "Works"],
  //   videos: ["Videos", "Videos", "Videos"],
  // order: 0,
  // },

 
  



  // {
  //   path: "phase-2",
  //   name: "PHASE 2",
  //   description: "Description",
  //   photos: ["path", "path", "path"],
  //   works: ["Works", "Works", "Works"],
  //   videos: ["Videos", "Videos", "Videos"],
  //   order: 0,
  // },


  // {
  //   path: "robert-nava",
  //   name: "ROBERT NAVA",
  //   description: "Description",
  //   photos: ["path", "path", "path"],
  //   works: ["Works", "Works", "Works"],
  //   videos: ["Videos", "Videos", "Videos"],
  //   order: 0,
  // },

 
  // {
  //   path: "t-kid",
  //   name: "T-KID",
  //   description: "Description",
  //   photos: ["path", "path", "path"],
  //   works: ["Works", "Works", "Works"],
  //   videos: ["Videos", "Videos", "Videos"],
  // order: 0,
  // },


];

const Artistes = () => {

  const [graff, setGraff] = React.useState(true);
  const [contemporain, setContemporain] = React.useState(true);
  const [photo, setPhoto] = React.useState(true);

  let clickGraff = () => {
    setContemporain(false);
    setPhoto(false);
    setGraff(true);

  }
  let clickContemporain = () => {
    setGraff(false);
    setPhoto(false);
    setContemporain(true);
  }
  let clickPhoto = () => {
    setGraff(false);
    setContemporain(false);
    setPhoto(true);

  }
  let click = () => {
    setGraff(true);
    setContemporain(true);
    setPhoto(true);
  }
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle}>ARTISTS</h1>
      <div className={MenuButton}>
      <button  className={NavButton} onClick={click}>
        All
      </button>/
      <button className={NavButton} onClick={clickGraff}>
        Post-graffiti & East Village
      </button>/
      <button className={NavButton} onClick={clickContemporain}>
        Other scenes
      </button>/
      <button className={NavButton} onClick={clickPhoto}>
        Photography
      </button>
      </div>
      <br />
      <div className={ListWrapper}>
       {graff && <div className={ColumnWrapper}>
          <h1 className={ListHeader}>Post-graffiti & East Village</h1>
          {artists.slice(0, 17).map((artist) => (
            artist.path ? 
            <a href={"/en/artistes/" + artist.path} className={LinkWrapper}>
              {artist.name}
            </a>
            : <p className={FalseLinkWrapper}>{artist.name}</p>
          ))}
          <br />
          {/* <hr class="rounded" style={{width: "200px", margin: "0 auto"}}></hr> */}
            {artists.slice(17, 27).map((artist) => (
              artist.path ? 
              <a href={"/en/artistes/" + artist.path} className={LinkWrapper}>
                {artist.name}
              </a>
              : <p className={FalseLinkWrapper}>{artist.name}</p>
            ))}
        </div>}
       {/* {graff && contemporain && photo && <hr class="rounded" style={{height: "70%",margin: "0", alignItems: "center", display: "flex", width: "0px"}}></hr> } */}
        {contemporain && <div className={ColumnWrapper}>
          <h1 className={ListHeader}>Other scenes</h1>
          {artists.slice(27, 49).map((artist) => (
            artist.path ? 
            <a href={"/en/artistes/" + artist.path} className={LinkWrapper}>
              {artist.name}
            </a>
            : <p className={FalseLinkWrapper}>{artist.name}</p>
          ))}
            <br />
            {/* <hr class="rounded" style={{width: "200px", margin: "0 auto"}}></hr> */}
            {!clickContemporain || (clickGraff && clickPhoto && clickContemporain) && <div className={ColumnWrapper}>
            <h1 className={ListHeader}>Photography</h1>
            {artists.slice(49, 50).map((artist) => (
              artist.path ? 
              <a href={"/en/artistes/" + artist.path} className={LinkWrapper}>
                {artist.name}
              </a>
              : <p className={FalseLinkWrapper}>{artist.name}</p>
            ))}
          </div>}
        <br />
        <h1 className={ListHeader}>GHOST PROJECT</h1>
            {artists.slice(50, 56).map((artist) => (
              artist.path ? 
              <a href={"/en-projects/artistes/" + artist.path} className={LinkWrapper}>
                {artist.name}
              </a>
              : <p className={FalseLinkWrapper}>{artist.name}</p>
            ))}
        </div>}
        
        {photo && !graff && !contemporain && <div className={ColumnWrapper}>
            <h1 className={ListHeader}>Photography</h1>
            {artists.slice(47, 49).map((artist) => (
              artist.path ? 
              <a href={"/en/artistes/" + artist.path} className={LinkWrapper}>
                {artist.name}
              </a>
              : <p className={FalseLinkWrapper}>{artist.name}</p>
            ))}
             <h1 className={ListHeader}>GHOST PROJECT</h1>
            {artists.slice(50, 56).map((artist) => (
              artist.path ? 
              <a href={"/fr-projects/artistes/" + artist.path} className={LinkWrapper}>
                {artist.name}
              </a>
              : <p className={FalseLinkWrapper}>{artist.name}</p>
            ))}
          </div>}
        {/* <hr class="rounded" style={{height:"70%", margin: "0 auto"}}></hr> */}

      </div>
        
      <div className={ButtonWrapper}>
        <Button
          alt=""
          onClick={() => {
            if (typeof window !== "undefined") {
              window.location.href = "/en/contactez-nous";
            }
          }}
        >
          Contact-us
        </Button>
      </div>
    </div>
  );
};

export default Artistes;
