// extracted by mini-css-extract-plugin
export var ButtonWrapper = "Artistes-module--ButtonWrapper --+lksG";
export var ColumnWrapper = "Artistes-module--ColumnWrapper--mlGeK";
export var FalseLinkWrapper = "Artistes-module--FalseLinkWrapper--xtept";
export var LinkWrapper = "Artistes-module--LinkWrapper--Qog0m";
export var ListHeader = "Artistes-module--ListHeader--cUor-";
export var ListWrapper = "Artistes-module--ListWrapper--ggdX3";
export var MenuButton = "Artistes-module--MenuButton--XHmyr";
export var NavButton = "Artistes-module--NavButton--EMnGm";
export var SubListWrapper = "Artistes-module--SubListWrapper--JKAIm";
export var Wrapper = "Artistes-module--Wrapper--LsAac";